import * as React from 'react';
import { Provider } from "react-redux";
import { createStore } from 'redux';
import { ReduxActions, ReduxState, rootReducer } from "../store";
import { responsiveFontSizes, ThemeProvider, CssBaseline } from '@material-ui/core';
import appTheme from "../theme/theme";
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";
import strings from "../localization/strings";
import AccessTokenRefresh from "./containers/access-token-refresh";
import MainScreen from './screens/main-screen';
import StoreInitializer from './containers/store-initializer';

/**
 * Initialize Redux store
 */
const store = createStore<ReduxState, ReduxActions, any, any>(rootReducer);

/**
 * Material UI automated responsive font sizes
 */
const theme = responsiveFontSizes(appTheme);

moment.locale(strings.getLanguage());

/**
 * App component
 */
const App: React.FC = () => (
  <ThemeProvider theme={ theme }>
    <CssBaseline />
    <Provider store={ store }>
      <AccessTokenRefresh>
        <StoreInitializer>
          <MainScreen/>
        </StoreInitializer>
      </AccessTokenRefresh>
    </Provider>
  </ThemeProvider>
);

export default App;