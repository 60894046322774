import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  scrollContainer: {
    overflowY: "auto",
    width: "100%",
    flexGrow: 1
  },

  searchBar: {
    flex: 1,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      flex: "unset",
      marginRight: 0,
      marginBottom: 20,
      width: "100%"
    }
  },

  pagination: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: 20
  },

  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  machineStatusCard: {
    minHeight: 100,
    margin: 20,
    padding: 25,
    "& .MuiCardContent-root:last-child": {
      padding: 0
    }
  },

  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },

  cardTitle: {
    margin: 0
  },

  filterContainer: {
    marginLeft: "auto",
    height: 56,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    },
    [theme.breakpoints.down("xs")]: {
      height: "fit-content"
    }
  },

  filterButton: {
    borderColor: theme.palette.grey[600],
    padding: "0 20px",
    [theme.breakpoints.down("xs")]: {
      padding: "10px 10px",
      "& .MuiToggleButton-label": {
        display: "flex",
        flexDirection: "column-reverse",
        justifyContent: "space-between",
        height: "100%"
      }
    }
  },

  indicator: {
    width: 15,
    height: 15,
    borderRadius: "50%",
    marginRight: 10,
    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      marginTop: 5,
      width: "100%",
      height: 3,
      borderRadius: 3
    }
  },

  filterButtonText: {
    color: theme.palette.grey[200],
    [theme.breakpoints.down("xs")]: {
      fontSize: 10
    }
  },

  online: {
    backgroundColor: theme.palette.success.light
  },

  missingSettings: {
    backgroundColor: theme.palette.warning.light
  },

  offline: {
    backgroundColor: theme.palette.error.light
  },

  disabled: {
    backgroundColor: theme.palette.grey[500]
  }

});
