import { createStyles } from "@material-ui/core";

export default createStyles({

  root: {
    width: "100%",
    height: 0,
    display: "flex",
    alignItems: "center",
    transition: "all 0.25s ease-in-out",
    overflow: "hidden",
    paddingLeft: 60
  },

  open: {
    height: 150,
    marginTop: 20,
    marginBottom: 0
  },

  selectedText: {
    marginLeft: 10,
    marginRight: 30
  }

});