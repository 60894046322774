import * as React from "react";
import { Button, Checkbox, FormControlLabel, Typography, withStyles, WithStyles } from "@material-ui/core";
import styles from "../../styles/generic/selection-toolbar";
import strings from "../../localization/strings";
import classNames from "classnames";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  statusesCount: number;
  selectedStatusesCount: number;
  onSelectAll: () => void;
  onDeselectAll: () => void;
  onEditSelected: () => void;
}

/**
 * Selection toolbar component
 *
 * @param props component props
 */
const SelectionToolbar: React.FC<Props> = ({
  classes,
  statusesCount,
  selectedStatusesCount,
  onSelectAll,
  onDeselectAll,
  onEditSelected
}) => {

  const allSelected = (
    !!statusesCount &&
    !!selectedStatusesCount &&
    statusesCount === selectedStatusesCount
  );

  /**
   * Component render
   */
  return (
    <div className={ classNames(classes.root, { [classes.open]: !!selectedStatusesCount }) }>
      <FormControlLabel
        label={ allSelected ? strings.deselectAll : strings.selectAll }
        control={
          <Checkbox
            color="primary"
            checked={ allSelected }
            onChange={ allSelected ? onDeselectAll : onSelectAll }
          />
        }
      />
      <Typography className={ classes.selectedText }>
        { strings.formatString(strings.selectedCount, selectedStatusesCount) }
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={ onEditSelected }
        disableElevation
        disabled={ !selectedStatusesCount }
      >
        { strings.editMultiple }
      </Button>
    </div>
  );
}

export default withStyles(styles)(SelectionToolbar);