import * as React from "react";
import { Button, Card, CardContent, Checkbox, Grid, MenuItem, TextField, Typography, withStyles, WithStyles } from "@material-ui/core";
import { styles } from "../../styles/generic/machine-status-list-item";
import { MachineStatus, NotificationType } from "../../generated/client";
import strings from "../../localization/strings";
import moment from "moment";
import theme from "../../theme/theme";
import { MachineStatusUtils } from "../../utils/machine-status-utils";
import { MonitoringStatus } from "../../types";
import classNames from "classnames";

/**
 * Interface describing component props
 */
interface Props extends WithStyles<typeof styles> {
  machineStatus: MachineStatus;
  selected: boolean;
  editedMachineStatusId?: string;
  editDisabled?: boolean;
  onToggleSelected: (machineStatus: MachineStatus) => void;
  onStartEdit: (machineStatusId?: string) => void;
  onStopEdit: () => void;
  onUpdate: (machineStatus: MachineStatus) => void;
  onDelete: (machineStatus: MachineStatus) => void;
}

/**
 * Machine status list item component
 *
 * @param props component props
 */
const MachineStatusListItem: React.FC<Props> = ({
  classes,
  machineStatus,
  selected,
  editedMachineStatusId,
  editDisabled,
  onToggleSelected,
  onStartEdit,
  onStopEdit,
  onUpdate,
  onDelete
}) => {
  const [ editedMachineStatus, setEditedMachineStatus ] = React.useState<MachineStatus | undefined>();


  /**
   * Save changes to machine status
   */
  const saveChanges = () => {
    editedMachineStatus && onUpdate(editedMachineStatus);
    toggleEdit();
  }

  /**
   * Toggles edit mode
   */
  const toggleEdit = () => {
    editedMachineStatus ?
      onStopEdit() :
      onStartEdit(machineStatus.id!);

    setEditedMachineStatus(editedMachineStatus ? undefined : { ...machineStatus });
  }

  /**
   * Event handler for on change value in machine status
   *
   * @param key key of machine status
   */
  const onChange = (key: keyof MachineStatus) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editedMachineStatus) {
      const modifiedMachineStatus: MachineStatus = {
        ...editedMachineStatus,
        [key]: event.target.value
      };

      if (key === "notificationType") {
        modifiedMachineStatus.notificationTarget = undefined;
      }

      setEditedMachineStatus(modifiedMachineStatus);
    }
  }

  /**
   * Toggles active state of machine status
   */
  const toggleActive = () => {
    const confirmMessage = strings.formatString(
      machineStatus.active ? strings.confirmDeactivate : strings.confirmActivate,
      machineStatus.machineId
    ) as string;

    if (window.confirm(confirmMessage)) {
      onUpdate({ ...machineStatus, active: !machineStatus.active });
    }
  }

  /**
   * Event handler for delete machine status
   */
  const onDeleteClick = () => {
    const confirmMessage = strings.formatString(strings.confirmDelete, machineStatus.machineId) as string;
    if (window.confirm(confirmMessage)) {
      onDelete(machineStatus);
    }
  }

  /**
   * Returns indicator color
   *
   * @returns
   * - Green if machine is online
   * - Yellow if machine is missing settings
   * - Red if machine is offline
   * - Grey if machine status monitoring is not active
   */
  const getIndicatorColor = () => {
    const monitoringStatus = MachineStatusUtils.getMonitoringStatus(machineStatus);
    switch (monitoringStatus) {
      case MonitoringStatus.DISABLED:
        return theme.palette.grey[500];
      case MonitoringStatus.MISSING_SETTINGS:
        return theme.palette.warning.light;
      case MonitoringStatus.OFFLINE:
        return theme.palette.error.light;
      case MonitoringStatus.ONLINE:
      default:
        return theme.palette.success.light;
    }
  }

  /**
   * Displays notification type
   *
   * @param notificationType notification type
   */
  const displayNotificationType = (notificationType: NotificationType) => ({
    [NotificationType.Email]: strings.emailMessage
  })[notificationType];

  /**
   * Renders notification target column
   *
   * @param notificationType notification type
   */
  const displayNotificationTargetTitle = (notificationType: NotificationType) => ({
    [NotificationType.Email]: strings.email
  })[notificationType];

  /**
   * Renders action buttons
   */
  const renderActionButtons = () => {
    if (editedMachineStatus) {
      return (
        <div className={ classes.actionButtonColumn }>
          <Button
            variant="contained"
            color="primary"
            className={ classes.containedButton }
            onClick={ saveChanges }
            disableElevation
          >
            { strings.save }
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={ classes.outlinedButton }
            onClick={ toggleEdit }
            disableElevation
          >
            { strings.cancel }
          </Button>
        </div>
      );
    }

    if (!machineStatus.active) {
      return (
        <div className={ classes.actionButtonColumn }>
          <Button
            variant="contained"
            color="primary"
            className={ classes.containedButton }
            onClick={ toggleActive }
            disableElevation
            disabled={ !!editedMachineStatusId || editDisabled }
          >
            { strings.activate }
          </Button>
        </div>
      );
    }

    return (
      <div className={ classes.actionButtonColumn }>
        <Button
          variant="outlined"
          color="primary"
          className={ classes.outlinedButton }
          onClick={ toggleEdit }
          disableElevation
          disabled={ !!editedMachineStatusId || editDisabled }
        >
          { strings.edit }
        </Button>
        <Button
          variant="contained"
          className={ classNames(classes.containedButton, classes.warning) }
          onClick={ toggleActive }
          disableElevation
          disabled={ !!editedMachineStatusId || editDisabled }
        >
          { strings.deactivate }
        </Button>
        <Button
          variant="contained"
          className={ classNames(classes.containedButton, classes.danger) }
          onClick={ onDeleteClick }
          disableElevation
          disabled={ !!editedMachineStatusId || editDisabled }
        >
          { strings.delete }
        </Button>
      </div>
    );
  }

  /**
   * Renders edit content
   */
  const renderEditContent = () => {
    return (
      <div className={ classes.textFieldContainer }>
        <TextField
          size="medium"
          variant="outlined"
          type="number"
          className={ classes.textField }
          label={ `${strings.notificationDelay} (${strings.hours})` }
          value={ editedMachineStatus?.notificationDelay || "" }
          InputLabelProps={{ variant: "outlined" }}
          onChange={ onChange("notificationDelay") }
        />
        <TextField
          select
          size="medium"
          variant="outlined"
          className={ classes.textField }
          label={ strings.notificationType }
          value={ editedMachineStatus?.notificationType || "" }
          InputLabelProps={{ variant: "outlined" }}
          onChange={ onChange("notificationType") }
        >
          { Object.values(NotificationType).map((option, index) =>
            <MenuItem key={ index } value={ option }>
              { displayNotificationType(option) }
            </MenuItem>)
          }
        </TextField>
        <TextField
          size="medium"
          variant="outlined"
          className={ classes.textField }
          label={ editedMachineStatus?.notificationType ?
            displayNotificationTargetTitle(editedMachineStatus?.notificationType) :
            strings.email
          }
          value={ editedMachineStatus?.notificationTarget || "" }
          InputLabelProps={{ variant: "outlined" }}
          onChange={ onChange("notificationTarget") }
        />
      </div>
    );
  }

  /**
   * Renders default content
   */
  const renderDefaultContent = () => {
    return (
      <>
        <div className={ classes.selectContainer }>
          <Checkbox
            color="primary"
            checked={ selected }
            onChange={ () => onToggleSelected(machineStatus) }
          />
        </div>
        <div className={ classes.indicatorContainer }>
          <div
            className={ classes.onlineIndicator }
            style={{ backgroundColor: getIndicatorColor() }}
          />
        </div>
        <Grid container>
          <Grid
            item
            xs={ 12 } sm={ 6 } md={ 8 } lg={ 4 } xl={ 1 }
            className={ classes.contentColumn }
          >
            <Typography variant="body2">
              { strings.machineId }
            </Typography>
            <Typography
              variant="h5"
              className={ classes.value }
            >
              { machineStatus.machineId }
            </Typography>
          </Grid>
          <Grid
            item
            xs={ 12 } sm={ 6 } md={ 4 } lg={ 4 } xl={ 2 }
            className={ classes.contentColumn }
          >
            <Typography variant="body2">
              { strings.companyNumber }
            </Typography>
            <Typography
              variant="h5"
              className={ classes.value }
            >
              { machineStatus.companyNumber || "-" }
            </Typography>
          </Grid>
          <Grid
            item
            xs={ 12 } sm={ 12 } md={ 8 } lg={ 4 } xl={ 2 }
            className={ classes.contentColumn }
          >
            <Typography variant="body2">
              { strings.lastUpdate }
            </Typography>
            <Typography
              variant="h5"
              className={ classes.value }
            >
              { moment(machineStatus.lastUpdate).fromNow() }
            </Typography>
          </Grid>
          <Grid
            item
            xs={ 12 } sm={ 12 } md={ 4 } lg={ 4 } xl={ 2 }
            className={ classes.contentColumn }
          >
            <Typography variant="body2">
              { strings.notificationDelay }
            </Typography>
            <Typography
              variant="h5"
              className={ classes.value }
            >
              { machineStatus.notificationDelay ?
                `${machineStatus.notificationDelay} ${strings.hours}` :
                strings.notDefined
              }
            </Typography>
          </Grid>
          <Grid
            item
            xs={ 12 } sm={ 12 } md={ 6 } lg={ 4 } xl={ 2 }
            className={ classes.contentColumn }
          >
            <Typography variant="body2">
              { strings.notificationType }
            </Typography>
            <Typography
              variant="h5"
              className={ classes.value }
            >
              { machineStatus.notificationType ?
                displayNotificationType(machineStatus.notificationType) :
                strings.emailMessage
              }
            </Typography>
          </Grid>
          <Grid
            item
            xs={ 12 } sm={ 12 } md={ 12 } lg={ 4 } xl={ 3 }
            className={ classes.contentColumn }
          >
            <Typography variant="body2">
              { machineStatus.notificationType ?
                displayNotificationTargetTitle(machineStatus.notificationType) :
                strings.email
              }
            </Typography>
            <Typography
              variant="h5"
              className={ classes.value }
            >
              { machineStatus.notificationTarget ?? strings.notDefined }
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  /**
   * Component render
   */
  return (
    <Card
      elevation={ 2 }
      className={ classes.machineStatusCard }
      style={{
        backgroundColor: machineStatus.active ?
          theme.palette.background.paper :
          theme.palette.action.disabledBackground
      }}
    >
      <CardContent className={ classes.cardContent }>
        { editedMachineStatus ?
          renderEditContent() :
          renderDefaultContent()
        }
      </CardContent>
      { renderActionButtons() }
    </Card>
  );
}

export default withStyles(styles)(MachineStatusListItem);