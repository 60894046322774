import { createStyles } from "@material-ui/core";
import theme from "../../theme/theme";

export const styles = createStyles({

  root: {},

  appBar: {},

  toolBar: {
    padding: 20,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center"
  }

});