import { createTheme } from "@material-ui/core";
import "fontsource-source-sans-pro";

export default createTheme({
  palette: {
    primary: {
      main: "#004C97"
    },
    secondary: {
      main: "#52A8D9"
    },
    background: {
      default: "#DCE2E9",
      paper: "#F7F9FC"
    },
    success: {
      main: "#18A855"
    },
    error: {
      main: "#D12121"
    },
    text: {
      primary: "#252729",
      disabled: "#C2C9CF"
    },
    grey: {
      "100": "#252729",
      "200": "#51575C",
      "400": "#939BA3",
      "600": "#C2C9CF",
      "700": "#DCE2E9",
      "800": "#F7F9FC",
      "900": "#FEFEFF"
    },
    info: {
      main: "#7BD4F0"
    },
    warning: {
      main: "#F6D46C"
    }
  },

  typography: {
    fontFamily: "Source Sans Pro, sans-serif"
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "::-webkit-scrollbar-track": {
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
          borderRadius: 10,
          backgroundColor: "none"
        },
        "::-webkit-scrollbar": {
          width: 6,
          backgroundColor: "#C2C9CF"
        },
        "::-webkit-scrollbar-thumb": {
          borderRadius: 10,
          webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
          backgroundColor: "#004C97"
        }
      }
    }
  }
});